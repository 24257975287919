import React, { useRef, useEffect, useState } from "react";
import AppHeader from "../pages/layouts/header";
import { setUserProfile } from "../user/userSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserAPI } from "../api/user.api";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Toaster } from "../common/components/ui/toaster";
import AppFooter from "../pages/layouts/app_footer";
import { useLocation, useNavigate } from "react-router-dom";
import cn from "../lib/utils";
import ReactGA from "react-ga";
import PublicHeader from "../pages/layouts/public_header";

export default function AppContainer(props: {
  children: React.ReactNode;
  lightMode?: boolean;
  minimalHeader?: boolean;
  noHeader?: boolean;
  noFooter?: boolean;
  isEditing?: boolean;
  allowedRole?: string;
  // roleRestriction?: string[];
}) {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { fetchUserProfile } = useUserAPI();
  const { profile, status } = useAppSelector((state: any) => state.user);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location])

  useEffect(() => {
    async function checkProfile() {
      if (isAuthenticated && !profile?.id) {
        await fetchUserProfile()
          .then((userProfile) => {
            dispatch(setUserProfile(userProfile));
            if (props.allowedRole && !userProfile?.roles?.includes(props.allowedRole)) {
              navigate("/");
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
    if (isAuthenticated && ["unregistered", "onboarding"].includes(status)) {
      navigate("/register");
    }
    checkProfile();
  }, [isLoading, isAuthenticated, status]);



  return (
    <div className={cn("page_container", (props.minimalHeader || props.lightMode) && "bg-white")}>
      {!props.noHeader && <PublicHeader minimalHeader={props.minimalHeader} theme={(props.minimalHeader || props.lightMode) ? "dark" : "lite"} />}
      <main className={!props.noFooter ? "mb-20" : ""}>
        {props.children}</main>
      <Toaster />
      {!props.noFooter && <AppFooter />}
    </div>
  );
}
