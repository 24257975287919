import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import ImageDetails from "./components/image_details";
import { Button } from "../../common/components/ui/button";
import { GenerateImageRequest, GenerativeModel } from "./models/image_generator";
import { useGenerativeAPI } from "../../api/generative_ai_api";
import { IconArrowCurrentFill } from "../../common/icons/icons";

const errors = {};

type ProgressData = {
    percent_complete: any;
    progress_message: any;
};

const ImageGenerator = ({ modelList, selectedPrompt, clearSelectedPrompt, showToast, setIsLoadingPage, setProgress }: { modelList: GenerativeModel[], selectedPrompt?: string, clearSelectedPrompt: () => void, showToast: (message: string) => void, setIsLoadingPage: (isLoading: boolean) => void, progress: any, setProgress: (progressData: ProgressData) => void }) => {
    const location = useLocation();
    const generativeData = location.state?.generativeData;
    const form = useForm<GenerateImageRequest>({ defaultValues: { aspect_ratio: "SQUARE" } });
    const navigate = useNavigate();
    const [taskQueueId, setTaskQueueId] = useState<string | null>(null);
    const [selectedIpVault, setSelectedIpVault] = useState<GenerativeModel | null>(null);
    const [selectedVaultList, setSelectedVaultList] = useState<GenerativeModel[]>([]);
    const promptValue = form.watch("prompt");

    let intervalId: NodeJS.Timeout;

    const { generateImageMedia, checkTaskQueueStatus } = useGenerativeAPI();

    // Set selected prompt from parent component
    useEffect(() => {
        if (selectedPrompt && selectedPrompt.trim() !== "") {
            form.setValue("prompt", selectedPrompt);
            const matchingVault = modelList.find(model => selectedPrompt.includes(`#${model.generative_tag}`));
            if (matchingVault) {
                setSelectedIpVault(matchingVault);
            }
            clearSelectedPrompt();
        }
    }, [selectedPrompt]);


    // Set selected models & form values if generative data is present
    // TODO: Figure out what to do for multiple models
    useEffect(() => {
        if (generativeData && modelList.length > 0) {

            const { prompt } = generativeData;

            const updatedSelectedVaults: GenerativeModel[] = [];

            modelList.forEach((model) => {
                const generativeTag = `#${model.generative_tag}`;
                if (prompt && prompt.includes(generativeTag)) {
                    updatedSelectedVaults.push(model);
                    setSelectedIpVault(model);
                }
            });
            setSelectedVaultList(updatedSelectedVaults);

            form.setValue("prompt", generativeData.prompt);
            form.setValue("aspect_ratio", generativeData.aspect_ratio);
        }
    }, [generativeData, modelList, form]);

    // Remove selected vaults if they are not in the prompt;
    useEffect(() => {
        if (selectedIpVault && !promptValue.includes(`#${selectedIpVault.generative_tag}`)) {
            setSelectedIpVault(null);
        }
    }, [promptValue, selectedIpVault]);


    // Handle set selected vaults & update prompt from vault select;
    const handleIpVaultSelect = (vault: GenerativeModel | null) => {
        const existingPrompt = form.getValues("prompt") || "";
        let updatedPrompt = existingPrompt;

        // Remove previous tag
        if (selectedIpVault) {
            const previousVault = selectedIpVault;
            const previousTag = `#${previousVault.generative_tag}`;
            updatedPrompt = updatedPrompt.replace(previousTag, "").trim();
        }

        if (vault) {
            const newGenerativeTag = `#${vault.generative_tag}`;
            updatedPrompt = `${newGenerativeTag} ${updatedPrompt}`.trim();
            setSelectedIpVault(vault);
        } else {
            setSelectedIpVault(null);
        }

        form.setValue("prompt", updatedPrompt);
    }

    const generateImage = async (data: any) => {
        setIsLoadingPage(true);
        if (!data.prompt) {
            setIsLoadingPage(false);
            showToast("Please enter a prompt");
            return;
        }
        try {
            const modelWeights = [];
            if (selectedIpVault) {
                if (selectedIpVault.model_type === "licensable_property") {
                    modelWeights.push({ id: selectedIpVault.id, weight: 0.8 });
                }
                if (selectedIpVault.model_type === "user_product") {
                    // Lower the weight of the product model if there is a talent model
                    modelWeights.push({ id: selectedIpVault.id, weight: (modelWeights.length === 0 ? 0.8 : 0.2) });
                }
            }
            const payload = {
                prompt: data.prompt,
                aspect_ratio: data.aspect_ratio ? data.aspect_ratio : "SQUARE",
                image_file_id: data.image_file_id,
                model_weights: modelWeights
            }

            const response = await generateImageMedia(payload);
            const taskQueueId = response.id;
            setTaskQueueId(taskQueueId);

            intervalId = setInterval(() => checkStatus(taskQueueId, intervalId), 1000);
            setTimeout(() => handleTimeout(), 2 * 60 * 1000);

        } catch (error) {
            console.error("Error generating image", error);
        }
    }

    const checkStatus = async (taskQueueId: string, intervalId: NodeJS.Timeout) => {
        if (!taskQueueId) return;

        try {
            const response = await checkTaskQueueStatus(taskQueueId);
            const completedTask = response.find((task: any) => task.status === "completed" && task.progress_status !== "processing");
            const failedTask = response.find((task: any) => task.progress_status === "failed");

            if (failedTask) {
                clearInterval(intervalId);
                setIsLoadingPage(false);
                showToast("Failed to generate image: " + failedTask.progress_message);
                return;
            }

            if (completedTask && completedTask.progress_status === "completed") {
                clearInterval(intervalId);
                navigate(`/image/editor/${taskQueueId}`);
                setIsLoadingPage(false);
                return;
            }


            const latestTask = response[response.length - 1];

            setProgress({
                percent_complete: latestTask.percent_complete || 0,
                progress_message: latestTask.progress_message
            })

        } catch (error) {
            console.error("Error checking status", error);
        }
    }



    const handleTimeout = () => {
        clearInterval(intervalId);
        setIsLoadingPage(false);
        showToast("Oops! It's taking a little too long to create your image. You can retry in a moment!")
    }

    return (
        <div className="">
            {generativeData && <Button variant="primary-negative" className="fill-white gap-x-2.5" onClick={() => navigate(`/image/editor/${generativeData.task_queue_id}`)}><><IconArrowCurrentFill />Return to edit</></Button>}
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(generateImage)}>
                    <ImageDetails
                        form={form}
                        errors={errors}
                        selectedVault={selectedIpVault}
                        handleVaultSelect={handleIpVaultSelect}
                        modelList={modelList} />
                </form>
            </FormProvider>
        </div>

    )
}


export default ImageGenerator;


