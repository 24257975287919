import React, { useState, useRef, useEffect } from "react";
import { useGenerativeAPI } from "../../api/generative_ai_api";
import { BoardDTO, GenerativeOutput } from "./models/image_generator";
import "./components/image_details.css";
import Heading from "../../common/components/heading";
import { DialogModal } from "../../common/components/ui/dialog_modal";
import { Button } from "../../common/components/ui/button";
import { TrashIcon, FavoriteIcon, PencilIcon, BackIcon, DownCarret } from "../../common/icons/icons";
import { useNavigate } from "react-router-dom";
import { aspectRatioOptions } from "./models/constants";
import edit_board from "../../common/components/ui/bit_image.png";

const MyGeneratedImages = ({
  board,
  boardList,
  favoritedIds,
  addToFavorite,
  removeFromBoard,
  moveImageToNewBoard,
  handleBack,
  updateBoard,
  handleDeleteBoard }: {
    board: BoardDTO,
    boardList: BoardDTO[],
    favoritedIds: string[],
    moveImageToNewBoard: (destinationBoardId: string, generative_output_id: string, sourceBoardId: string) => void;
    removeFromBoard: (boardId: string, generative_output_id: string) => void;
    addToFavorite: (generativeOutputId: string) => void;
    handleBack: () => void,
    updateBoard: (boardId: string, updatedFields: Partial<BoardDTO>) => void,
    handleDeleteBoard: (boardId: string) => void
  }) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<"imageDetails" | "editBoard">("imageDetails");
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [selectedBoard, setSelectedBoard] = useState<BoardDTO>(board);
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const [localFavorites, setLocalFavorites] = useState<string[]>(favoritedIds);

  const [updatedBoard, setUpdatedBoard] = useState<
    {
      name?: string,
      description?: string,
    }>({
      name: board.name,
      description: board.description
    });

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const { getGenerativeOutput, deleteImage } = useGenerativeAPI();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDisplayDropdown(false);
      }
    };

    if (displayDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [displayDropdown]);

  // This is to delete actual image
  const deleteMyImage = (taskQueueId: string) => async () => {
    try {
      await deleteImage(taskQueueId);

      setOpenModal(false);
      setSelectedImage(null);


    } catch (error) {
      console.error("Error deleting image", error);
    }
  }

  const removeImageFromBoard = () => {
    const imageId = selectedImage.generative_output_id;
    removeFromBoard(board.id || "", imageId);
    setOpenModal(false);
  }

  const openImageDetails = async (image: GenerativeOutput) => {
    if (!image.task_queue_id) return;

    const response = await getGenerativeOutput(image.task_queue_id);
    const aspectRatioDetail = aspectRatioOptions.find(
      (option) => option.key === response.aspect_ratio
    );

    const aspectRatioLabel = aspectRatioDetail
      ? `${aspectRatioDetail.label} ${aspectRatioDetail.description}`
      : "Unknown";

    // TODO: if the image is a child, we may not have this data
    const updatedImage = {
      ...image,
      prompt: response.prompt,
      aspect_ratio_label: aspectRatioLabel
    };

    setSelectedImage(updatedImage);
    setModalType("imageDetails");
    setOpenModal(true);
  }

  const openEditModal = () => {
    setOpenModal(true);
    setModalType("editBoard");
  }

  const handleUpdateBoard = (boardId: string) => {
    if (updatedBoard.name !== board.name || updatedBoard.description !== board.description) {
      updateBoard(boardId, {
        name: updatedBoard.name,
        description: updatedBoard.description
      });
      setOpenModal(false);
    }

  }

  const handleFavoriteClick = (e: React.MouseEvent, boardId: string, generativeOutput: GenerativeOutput) => {
    const generativeOutputId = generativeOutput.generative_output_id;
    e.stopPropagation();
    const isFavorited = localFavorites.includes(generativeOutputId);

    setLocalFavorites(prev =>
      isFavorited
        ? prev.filter(id => id !== generativeOutputId)
        : [...prev, generativeOutputId]
    );

    addToFavorite(generativeOutputId);

  }

  return (
    <div className="page_content">
      <DialogModal
        isOpen={openModal}
        onOpenChange={setOpenModal}
        onClose={() => { setOpenModal(false); setSelectedImage(null); setSelectedBoard(board); setUpdatedBoard({ name: board.name, description: board.description }) }}
        variant="large"
        removeFooter
      >
        {modalType === "imageDetails" && selectedImage &&
          <div className="flex flex-row gap-16 p-5">
            <img className="w-[452px] h-[577px] object-cover rounded-lg" src={selectedImage.permalink} />
            <div className="flex flex-col gap-4 justify-center">
              <Heading as="h3">Image details</Heading>
              <p> {selectedImage.prompt}</p>
              <p><span className="font-semibold">Resolution:</span>{`  ${selectedImage.aspect_ratio_label}`}</p>
              {/* {selectedImage.modelData
                ?.filter((it: any) => it != null) // Filter out null or undefined
                .map((model: { entity_type: string; name: string }, index: any) => (
                  <p key={index}>
                    <span className="font-semibold">
                      {model.entity_type === "USER_PRODUCT" ? "Product: "
                        : model.entity_type === "BRAND" ? "Brand: "
                          : model.entity_type === "LICENSABLE_PROPERTY" ? "Talent: "
                            : "IP Vault: "}
                    </span> {model.name}
                  </p>
                ))} */}

              <div className="relative flex flex-row gap-5">
                <div onClick={() => setDisplayDropdown(true)} className="flex flex-row justify-between items-center px-5 w-full border rounded-[20px] border-gray-300 cursor-pointer">
                  <div>{board === selectedBoard ? `Saved to: ${board.name}` : selectedBoard.name}</div>
                  <DownCarret />
                </div>
                {displayDropdown && <div ref={dropdownRef} className="text-white w-full h-[225px] cursor-pointer absolute mt-2 p-7 space-y-3 rounded-2xl z-50 bg-blackish overflow-scroll">
                  {boardList.map(board => <div key={board.id} onClick={() => { setSelectedBoard(board); setDisplayDropdown(false) }} className="p-1 font-body flex flex-row justify-between">
                    {board.name}
                    {/* {selectedBoard?.id === board.id && <SelectedIcon />} */}
                  </div>)}
                </div>}
                <Button variant="primary-negative" onClick={() => { moveImageToNewBoard(selectedBoard.id || "", selectedImage.generative_output_id, board.id || ""); setOpenModal(false) }}>Save</Button>
              </div>

              {/* TODO Apply for license */}
              {/* <p className="font-semibold">License: <Button className="text-black font-semibold outline-none" variant="link"><>Apply for a license <IconArrowRight /> </></Button></p> */}
              <div className="flex gap-2">
                <Button variant="outline-official" className="rounded-[20px] border-gray-300 hover:fill-white" onClick={() => navigate(`/image/editor/${selectedImage.task_queue_id}`)}>Edit image</Button>
                <Button variant="outline-official" className="rounded-[20px] border-gray-300 hover:fill-white" onClick={() => removeImageFromBoard()}>Delete from board</Button>
              </div>
            </div>

          </div>
        }

        {modalType === "editBoard" && (
          <div className="flex gap-24">
            <img className="rounded-[20px]" src={edit_board} />

            <div className="flex flex-col justify-center space-y-6">
              <Heading as="h3">Edit board</Heading>
              <div className="flex flex-col">
                <label className="italic font-extralight">Name of board</label>
                <input
                  className="border-0 border-b-2 border-blackish pt-2 focus:ring-0 outline-none"
                  type="text"
                  value={updatedBoard.name}
                  onChange={(e) => {
                    setUpdatedBoard(prev => ({ ...prev, name: e.target.value }))
                  }} />
              </div>
              <p>Description</p>
              <textarea
                value={updatedBoard.description}
                onChange={(e) => {
                  setUpdatedBoard(prev => ({ ...prev, description: e.target.value }))
                }}
                className="w-[585px] h-[168px] bg-gray-200 resize-none rounded-xl p-3 focus:ring-0 outline-none focus:shadow-none" placeholder="Enter description..." />
              <Button className="self-start" onClick={() => board.id && handleUpdateBoard(board.id)} variant="primary-negative">Save</Button>
            </div>


          </div>
        )}
      </DialogModal>

      <div className="text-white px-[120px] pt-16 pb-32">
        <Button className="gap-2 px-6 py-3 fill-white hover:fill-black" variant="outline" onClick={handleBack}><><BackIcon />Back</></Button>
        <div className="pt-9 pb-[75px]">
          <div className="flex flex-row justify-between items-center ">
            <div className="flex flex-row items-center gap-3">
              <h1 className="text-5xl">{`${board.name} ${board.generative_outputs && `(${board.generative_outputs.length})`}`}</h1>
              <Button className="h-11 w-11 border-none" onClick={openEditModal} variant="icon"><div><PencilIcon /></div></Button>
            </div>
            {board.is_deletable && <Button onClick={() => board.id && handleDeleteBoard(board.id)} className="h-full fill-white gap-2 px-6 py-3 hover:fill-black" variant="outline"><><TrashIcon />Delete board</></Button>}
          </div>
          <p className="text-gray-500 text-xl pt-3">{board.description}</p>
        </div>
        <div className={"flex flex-row flex-wrap gap-[52px]"}>
          {board?.generative_outputs && board.generative_outputs.length > 0 && board.generative_outputs.map((image, index) => (
            <div key={index} onClick={() => openImageDetails(image)} className={"image-card h-[460px] w-[360px] rounded-2xl"}>
              <div className="image-card-inner"
                style={{
                  backgroundImage: `url(${image.permalink})`
                }}>
                <div onClick={(e) => {
                  handleFavoriteClick(e, board.id || "", image);
                }} className="absolute top-5 right-5 fill-white cursor-pointer">
                  <FavoriteIcon isFavorited={localFavorites.includes(image.generative_output_id)} /></div>
              </div>
            </div>
          )
          )}

        </div>
      </div>


    </div >
  )


}

export default MyGeneratedImages;