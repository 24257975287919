import React from "react";
import Panel from "./panel";
import { Button } from "../../../../common/components/ui/button";
import { VersionHistory } from "../../models/image_generator";
import { format } from "date-fns";


const VersionHistoryListPanel = ({ versionHistory, closePanel, helperText, expanded, handleToggleExpand }: {
    helperText?: string,
    expanded: boolean | undefined,
    handleToggleExpand: () => void, versionHistory: VersionHistory[], closePanel?: () => void
}) => {
    const compareVersions = (currentItem: VersionHistory) => {
        if (currentItem.action === "upscale") {
            return "Upscale image"
        } else if (currentItem.action === "generate_media") {
            return "Generate variation";
        } else if (currentItem.action === "text2image") {
            return `Edit image: ${currentItem.inpaint_prompt ? currentItem.inpaint_prompt : currentItem.prompt}`;
        } else if (currentItem.action === "outpainting") {
            return "Update aspect ratio";

            // } else if (currentItem.prompt !== prevItem.prompt) {
            //     return `"${currentItem.prompt}"`;
            // } else if (currentItem.aspect_ratio !== prevItem.aspect_ratio) {
            //     return "Update aspect ratio";
            // } else if (previousItem.toString() === currentItem.toString()) {
            //     return "Variation of inital prompt";
        } else {
            return `"${currentItem.prompt}"`;
        }
    }

    const formattedVersionHistory = versionHistory.map((item) => {
        return {
            ...item,
            formattedDate: format(new Date(item.created_at), "M/d/yy"),
            formattedTime: format(new Date(item.created_at), "h:mma"),
            cliffnoteAction: item.action ? compareVersions(item) : `Generate image: "${item.prompt}"`,
        }
    });
    return (
        <Panel toggleExpanded={handleToggleExpand} helperText={helperText} isExpanded={expanded} title="History" closePanel={closePanel}>
            <div className="max-h-[476px] h-auto overflow-scroll">
                {formattedVersionHistory.map((versionHistory, index) => (
                    <div className="flex flex-row justify-between items-center pb-5" key={index}>
                        <div className="flex flex-row items-center gap-4">
                            <img className="max-w-[72px] min-w-[72px] h-[72px] object-cover rounded-full"
                                src={versionHistory.thumbnail_image ? versionHistory.thumbnail_image : versionHistory.permalink} />
                            <div className="flex w-[50px] flex-col text-sm-sans text-gray-300 lowercase">
                                <p>{versionHistory.formattedDate}</p>
                                <p>{versionHistory.formattedTime}</p>
                            </div>
                            <p className="text-white w-24 md:w-32 lg:w-48 truncate">{versionHistory.cliffnoteAction}</p>
                        </div>
                        <Button variant="outline" onClick={() => {
                            window.location.href = `/image/editor/${versionHistory.task_queue_id}`;
                        }} className="text-white border-gray-300 hover:bg-black hover:text-white">View</Button>
                    </div>
                ))}
            </div>

        </Panel>
    )
}

export default VersionHistoryListPanel;