import React from "react";
import { Panel } from "../models/image_generator";
import {
    CopyIcon,
    DownloadIcon,
    EditAspectRatioIcon,
    EditDirectlyIcon,
    EditPromptIcon,
    SaveIcon,
    Heart,
    UpscaleIcon,
    ThumbsUp,
    HistoryIcon,
    SearchIcon,
    DragIcon
} from "../../../common/icons/icons";


export const PANELS: Omit<Panel, "active" | "component" | "isExpanded">[] = [
    {
        id: "Description",
        name: "Description",
        icon: <EditPromptIcon />,
        description: "Description",
        details: "Fine-tune the text prompt that created this image. For major changes, we recommend generating a new image from scratch."
    },
    {
        id: "Versions",
        name: "Versions",
        icon: <CopyIcon />,
        description: "Versions",
        details: "Browse different versions of this image to find the perfect match for your needs."
    },
    {
        id: "Edit directly",
        name: "Edit directly",
        icon: <EditDirectlyIcon />,
        description: "Edit directly",
        details: "Need to tweak something specific? Use our smart tools to adjust particular areas of your image, like swapping backgrounds or modifying objects."
    },
    {
        id: "History",
        name: "History",
        icon: <HistoryIcon />,
        description: "History",
        details: "See every step of your creative journey - from initial generation to final touches. Like having an 'undo' superpower!",
        addIconStroke: true
    },

    {
        id: "Zoom",
        name: "Zoom",
        isShort: true,
        icon: <SearchIcon />,
        description: "Zoom",
        details: "Get up close and personal with your image details or zoom out for the big picture."
    },
    {
        id: "Drag",
        name: "Drag",
        isShort: true,
        icon: <DragIcon />,
        description: "Move around image",
        details: "Click and drag to move around the image, especially useful when zoomed in for detailed work."
    },
    {
        id: "Aspect ratio",
        name: "Aspect ratio",
        icon: <EditAspectRatioIcon />,
        description: "Change dimensions",
        details: "Quick resize for different platforms - from Instagram squares to Facebook covers. Includes handy presets for all major formats."
    },
    {
        id: "Upscale",
        name: "Upscale",
        isShort: true,
        icon: <UpscaleIcon />,
        description: "Enhance resolution",
        details: "Make your image bigger and sharper - perfect for those times when you need high-resolution output."
    },
    {
        id: "Favorite image",
        name: "Favorite image",
        isShort: true,
        icon: <Heart />,
        description: "Favorite",
        details: "Save this version to your inspiration board for easy access later.",
        addIconStroke: true
    },
    {
        id: "Download image",
        name: "Download image",
        isShort: true,
        icon: <DownloadIcon />,
        description: "Download",
        details: "Save this image to your local device with all rights and authenticity certificates included."
    },
    {
        id: "Save image",
        name: "Save image",
        isShort: true,
        icon: <SaveIcon />,
        description: "Save",
        details: "Store this version in your project board with all its history and info intact.",
        addIconStroke: true
    },
    {
        id: "Feedback",
        name: "Feedback",
        isShort: true,
        icon: <ThumbsUp />,
        description: "Feedback",
        details: "Help us make the magic even better! Rate image quality or let us know if something's not quite right."
    },
]