import React from "react";
import Panel from "./panel";
import AspectRatioCardGroup from "../aspect_ratio_card";
import { aspectRatioOptions } from "../../models/constants";
import { GenerateMediaResult } from "../../models/image_generator";

const AspectRatioPanel = ({ generativeData, handleAspectRatioChange, closePanel, expanded, helperText, handleToggleExpand }: {
    expanded: boolean | undefined,
    helperText?: string,
    handleToggleExpand: () => void,
    generativeData: GenerateMediaResult | null, handleAspectRatioChange: (aspectRatio: string) => void, closePanel: () => void
}) => {
    return (
        <Panel helperText={helperText} toggleExpanded={handleToggleExpand} isExpanded={expanded} title="Aspect ratio" closePanel={closePanel}>
            <AspectRatioCardGroup options={aspectRatioOptions} selected={generativeData?.aspect_ratio ?? "SQUARE"}
                onSelect={handleAspectRatioChange} size="small" />
        </Panel>
    )
}

export default AspectRatioPanel;