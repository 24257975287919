import React, { useState } from "react";
import SmallPanel from "./small_panel";
import { BrushPlusIcon, BrushMinusIcon } from "../../../../common/icons/icons";

const ZoomPanel = ({ scale, zoomIn, zoomOut }: { scale: any, zoomIn: () => void, zoomOut: () => void }) => {

    return (
        <SmallPanel>
            <div className="p-3 space-y-2">
                <p>Zoom</p>
                <div className="flex flex-row gap-3 items-center">
                    <div onClick={zoomOut} className="border-2 border-gray-300 rounded-full flex items-center justify-center h-9 w-9 stroke-blackish"><BrushMinusIcon /></div>
                    {/* TODO don't do this here */}
                    {(scale * 100).toFixed(0)}%
                    <div onClick={zoomIn} className="border-2 border-gray-300 rounded-full flex items-center justify-center h-9 w-9 stroke-blackish"><BrushPlusIcon /></div>
                </div>
            </div>
        </SmallPanel>
    )
}

export default ZoomPanel;