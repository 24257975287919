import React, { useState, useEffect } from "react";
import bit_image from "../../common/components/ui/bit_image.png";
import { Button } from "../../common/components/ui/button";
import { DialogModal } from "../../common/components/ui/dialog_modal";
import { useImageBoardAPI } from "../../api/image_board_api";
import { BoardDTO, GenerativeOutput } from "./models/image_generator";
import MyGeneratedImages from "./my_generated_images";
import { ErrorIcon, Plus } from "../../common/icons/icons";
import BoardGrid from "./components/board_grid";


const ImageGallery = () => {
    const [openModal, setOpenModal] = useState(false);
    const [boards, setBoards] = useState<BoardDTO[]>([]);
    const [boardName, setBoardName] = useState("");
    const [error, setError] = useState("");
    const [favoritedBoardIds, setFavoritedBoardIds] = useState<string[]>([]);
    const [selectedBoard, setSelectedBoard] = useState<BoardDTO | null>();
    const [actionToast, setActionToast] = useState<
        {
            showToast: boolean,
            toastMessage: string,
        }>({
            showToast: false,
            toastMessage: "",
        });

    const { getImageBoards,
        saveNewBoard,
        saveImageToBoard,
        removeImageFromBoard,
        deleteBoard,
        moveImageToDifferentBoard,
        addOrRemoveFavorite,
        updateBoard } = useImageBoardAPI();

    useEffect(() => {
        const handlePopState = () => {
            setSelectedBoard(null);
        };

        window.addEventListener("popstate", handlePopState);
        return () => window.removeEventListener("popstate", handlePopState);
    }, []);



    const handleSelectBoard = (board: BoardDTO) => {
        setSelectedBoard(board);
        window.history.pushState({ boardView: true }, "", "");
    };

    const handleBack = () => {
        setSelectedBoard(null);
        window.history.back();
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const myBoards = await getImageBoards();
                const favorites = myBoards.find(board => board.is_favorite);
                const favoriteIds = favorites?.generative_outputs?.map(item => item.generative_output_id) || [];
                setFavoritedBoardIds(favoriteIds);

                myBoards.sort((a, b) => {
                    if (a.is_favorite && !b.is_favorite) return -1;
                    if (!a.is_favorite && b.is_favorite) return 1;
                    if (a.is_default && !b.is_default) return -1;
                    if (!a.is_default && b.is_default) return 1;
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                });

                setBoards(myBoards);
            } catch (error) {
                console.error("Error fetching generative output", error);
            }
        };

        fetchData();
    }, []);

    const createImageBoard = async () => {
        if (!boardName.trim()) {
            setError("Board name is required");
            return;
        } else {
            setError("");
        }

        const request: BoardDTO = {
            created_at: new Date().toISOString(),
            name: boardName,
        };

        try {
            const response = await saveNewBoard(request);
            setBoardName("");
            setBoards(prevBoards => {
                const updatedBoards = [...prevBoards, response];

                updatedBoards.sort((a, b) => {
                    if (a.is_favorite && !b.is_favorite) return -1;
                    if (!a.is_favorite && b.is_favorite) return 1;
                    if (a.is_default && !b.is_default) return -1;
                    if (!a.is_default && b.is_default) return 1;
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                });

                return updatedBoards;
            });
            setOpenModal(false);
            showToast(`${response.name} created!`);
        } catch (error) {
            console.error("Error creating board", error);
        }
    };

    const addImageToBoard = async (boardId: string, generative_output_id: string) => {
        if (!boardId || !generative_output_id) {
            showToast("Error adding image to board");
            return;
        }

        const response = await saveImageToBoard(boardId, generative_output_id);
        setBoards((prevBoards) =>
            prevBoards.map((b) => (b.id === boardId ? response : b))
        );
        setSelectedBoard(response);
    }

    const removeGenOutputFromBoard = async (boardId: string, generative_output_id: string) => {
        if (!boardId || !generative_output_id) {
            showToast("Error removing image to board");
            return;
        }

        const response = await removeImageFromBoard(boardId, generative_output_id);

        setBoards((prevBoards) =>
            prevBoards.map((board) => {
                if (board.id === boardId) {
                    return {
                        ...board,
                        generative_outputs: board.generative_outputs?.filter(
                            (output) => output.generative_output_id !== generative_output_id)
                    };
                }
                return board;
            })
        )
        setSelectedBoard(response);

    }


    const moveImageToNewBoard = async (destinationBoardId: string, generative_output_id: string, sourceBoardId: string) => {
        if (!destinationBoardId || !generative_output_id || !sourceBoardId) {
            showToast("Error moving image to board");
            return;
        }

        const response = await moveImageToDifferentBoard(destinationBoardId, generative_output_id, sourceBoardId);
        showToast("Image moved to new board");

        setBoards((prevBoards) =>
            prevBoards.map((board) =>
                board.id === destinationBoardId
                    ? response
                    : board.id === sourceBoardId
                        ? {
                            ...board,
                            generative_outputs: board?.generative_outputs?.filter(
                                (output) => output.generative_output_id !== generative_output_id
                            )
                        }
                        : board
            )
        );

        if (selectedBoard?.id === destinationBoardId) {
            setSelectedBoard(response);
        } else if (selectedBoard?.id === sourceBoardId) {
            setSelectedBoard(prevSelectedBoard => prevSelectedBoard
                ? {
                    ...prevSelectedBoard,
                    generative_outputs: prevSelectedBoard.generative_outputs?.filter(
                        (output) => output.generative_output_id !== generative_output_id
                    ) ?? []
                }
                : null
            );
        }
    }

    const favoriteImage = async (generative_output_id: string) => {
        const updatedBoard = await addOrRemoveFavorite(generative_output_id);
        setFavoritedBoardIds((prevIds) =>
            prevIds.includes(generative_output_id)
                ? prevIds.filter((id) => id !== generative_output_id)
                : [...prevIds, generative_output_id]
        );

        setBoards((prevBoards) =>
            prevBoards.map((board) =>
                board.is_favorite ? updatedBoard : board,
            )
        );

        if (selectedBoard?.is_favorite) {
            setSelectedBoard(updatedBoard);
        }
    }

    const deleteImageBoard = async (boardId: string) => {
        deleteBoard(boardId);
        setBoards(boards.filter(board => board.id !== boardId));
        setSelectedBoard(null);
        showToast("Board deleted");
    }

    const updateImageBoard = async (boardId: string, updatedFields: Partial<BoardDTO>) => {
        const existingBoard = boards.find(board => board.id === boardId);
        if (!existingBoard) return;

        const updatedBoard: BoardDTO = {
            ...existingBoard,
            ...updatedFields,
            updated_at: new Date().toISOString(),
        }

        try {
            const response = await updateBoard(boardId, updatedBoard);
            setBoards((prevBoards) =>
                prevBoards.map((b) => (b.id === boardId ? response : b))
            );
            setSelectedBoard(response);
        } catch (error) {
            console.error("Error updating board", error);
        }

    };

    const showToast = (message: string) => {
        setActionToast({ showToast: true, toastMessage: message });
        setTimeout(() => {
            setActionToast(prevState => ({ ...prevState, showToast: false, toastMessage: "" }));
        }, 3000);
    }


    return (
        <div>
            {selectedBoard ? <MyGeneratedImages
                addToFavorite={favoriteImage}
                removeFromBoard={removeGenOutputFromBoard}
                moveImageToNewBoard={moveImageToNewBoard}
                favoritedIds={favoritedBoardIds}
                handleBack={handleBack}
                updateBoard={updateImageBoard}
                handleDeleteBoard={deleteImageBoard}
                board={selectedBoard}
                boardList={boards} /> :
                <div>
                    <DialogModal
                        isOpen={openModal}
                        onOpenChange={setOpenModal}
                        onClose={() => { setOpenModal(false); setBoardName(""); setError("") }}
                        variant="large"
                    >
                        {/* <CreateBoard boards={boards} setBoards={setBoards} closeModal={() => { setOpenModal(false); setBoardName(""); setError("") }} /> */}
                        <img className="rounded-lg w-" src={bit_image} />
                        <div className="flex flex-col p-16 space-y-7">
                            <h1 className="text-4xl">Create image board</h1>
                            <div className="space-y-2">
                                <div className="flex flex-col">
                                    <label className="italic font-extralight">Name of board</label>
                                    <input
                                        className={`border-0 border-b-2 ${error ? "border-brand-error" : "border-blackish"} pt-2 focus:ring-0 outline-none`}
                                        type="text"
                                        value={boardName}
                                        onChange={(e) => { setBoardName(e.target.value); setError("") }} />
                                </div>
                                {error && <div className="text-brand-error text-sm flex flex-row items-center gap-2"><ErrorIcon />{error}</div>}
                            </div>
                            <Button className="self-start w-auto" variant="primary-negative" onClick={createImageBoard}>Create board</Button>
                        </div>
                    </DialogModal>
                    <div className="text-white px-[120px] pt-28 pb-24">
                        <h1 className="text-5xl pb-9">Boards</h1>
                        <div className="flex flex-row flex-wrap gap-10">
                            <div className="bg-gray-900 h-[347px] w-[280px] rounded-[20px] flex flex-col gap-3 justify-center items-center">
                                <p>Create board</p>
                                <Button className="h-12 w-12 flex-shrink-0 border-none" onClick={() => setOpenModal(true)} variant="primary"><div><Plus /></div></Button>
                            </div>
                            {boards.map((board, id) => (
                                <div key={id}>
                                    <BoardGrid board={board} handleBoardSelect={handleSelectBoard} />
                                    {board.name}
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            }
            {actionToast.showToast && <div className="fixed bottom-4 rounded-3xl right-9 bg-white px-6 py-5 text-blackish w-[453px]">{actionToast.toastMessage}</div>}
        </div>
    );


}

export default ImageGallery;