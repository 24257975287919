import React, { useRef, useState } from "react";
import { Button } from "../../common/components/ui/button";
import { useFileAPI } from "../../api/file_api";
import { useGenerativeAPI } from "../../api/generative_ai_api";
import { useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";

const UploadImage = () => {
    const { uploadFile } = useFileAPI();
    // TODO handle loading
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { profile } = useAppSelector((state: any) => state.user);
    const { initStarterImage, getModels } = useGenerativeAPI();
    const navigate = useNavigate();

    const browseForStarterImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files: File[] = event.target.files ? Array.from(event.target.files) : [];
        if (files.length > 0) {
            setUploading(true);
            uploadFile(
                {
                    files: files,
                    is_public: false,
                    generate_thumbnail: false,
                    object_id: profile.id,
                    object_type: "user",
                    usage: "starter_image",
                },
                (progressEvent) => {
                    setUploadProgress((progressEvent.progress || 0) * 100);
                }
            ).then((response) => {
                setUploading(false);
                if (response.length > 0 && response[0].id) {
                    initStarterImage({ file_id: response[0].id }).then((response) => {
                        navigate("/image/editor/" + response.id);
                    }).catch((error) => {
                        console.error(error);
                    })
                }
            });

        }
    };
    return (
        <div className="flex justify-center items-center h-screen text-white">
            <div className="bg-gray-900 rounded-[20px] gap-9 w-[684px] h-[503px] flex flex-col justify-center items-center">
                <img src={require("../../common/components/ui/upload.png")} />
                <div className="flex flex-col text-center">
                    <p className="text-3xl">Click to upload image</p>
                    <p>Rearrange objects in your scene, blend objects from multiple<br /> images, place characters or expand edges</p>
                </div>
                <Button onClick={browseForStarterImage}
                    disabled={uploading}>{uploading ? "Uploading file ..." : "Upload image"}</Button>
                <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                />
            </div>
        </div>
    );


}

export default UploadImage;