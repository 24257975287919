import React from "react";

const SmallPanel = ({ children, title, action }: { children?: React.ReactNode, title?: string, action?: () => void }) => {
    const test = () => {
        action && action();
    }
    return (
        <div
            className="absolute left-full select-none top-0 bg-white text-blackish rounded-lg shadow-lg w-[183px] p-2 z-50 hover:cursor-pointer"
            style={{ marginLeft: "13.5px" }}
        >
            <div onClick={test} className="">
                {title}
            </div>
            {children && <div>
                {children}
            </div>}
        </div>
    )
}

export default SmallPanel;