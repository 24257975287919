import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import "./image_details.css";
import { Button } from "../../../common/components/ui/button";
import { Sparkles, CircleX } from "../../../common/icons/icons";
import IpVaultModal from "./modal-components/IP_vault_modal";
import { useGenerativeAPI } from "../../../api/generative_ai_api";
import loading_light from "../../../common/components/ui/loading_light.gif";
import { GenerativeModel } from "../models/image_generator";
import { DialogModal } from "../../../common/components/ui/dialog_modal";
import AspectRatio from "./modal-components/aspect_ratio";

const ImageDetails = ({ form,
    errors,
    selectedVault,
    handleVaultSelect,
    modelList,
}: {
    form: any,
    errors: any,
    modelList: GenerativeModel[],
    selectedVault: GenerativeModel | null,
    handleVaultSelect: (vault: GenerativeModel | null) => void
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [aspectRatioModalOpen, setAspectRatioModalOpen] = useState(false);
    const [isIpVaultOpen, setIsIpVaultOpen] = useState(false);
    const { enhancePrompt } = useGenerativeAPI();
    const { setValue } = useFormContext();

    const enhanceTextPrompt = async () => {
        setIsLoading(true);
        const models = []
        if (selectedVault) {
            models.push(selectedVault.id);
        }
        const response = await enhancePrompt(form.getValues("prompt"), models);
        if (response && response.enhanced) {
            setValue("prompt", response.enhanced);
        }
        setIsLoading(false);
    }

    const handleAspectRatioChange = (newAspectRatio: string) => {
        setValue("aspect_ratio", newAspectRatio);
        setAspectRatioModalOpen(false);
    }

    return (
        <div className="pt-5 space-y-5">
            <DialogModal
                isOpen={aspectRatioModalOpen}
                onOpenChange={setAspectRatioModalOpen}
                onClose={() => { setAspectRatioModalOpen(false) }}
                variant="large"
                removeFooter
            >
                <AspectRatio currentAspectRatio={form.watch("aspect_ratio") ?? "SQUARE"} onSave={handleAspectRatioChange} />
            </DialogModal>
            <div className="flex flex-col space-y-5">
                <Button onClick={() => setIsIpVaultOpen(true)} variant="primary-negative-invert" type="button" className="border-none self-start w-auto text-base font-semibold">{selectedVault ? "1 vault selected" : "Select vault"}</Button>
                <IpVaultModal
                    isIpModalOpen={isIpVaultOpen}
                    setIsIpModalOpen={setIsIpVaultOpen}
                    selectedVault={selectedVault}
                    modelList={modelList}
                    confirmIpSelect={handleVaultSelect} />
                {selectedVault && (
                    <div className="bg-gray-100 text-black w-[284px] p-4 rounded-xl flex flex-row gap-3 items-center justify-between">
                        <div className="flex flex-row items-center gap-3">
                            <img className="object-cover h-[70px] w-[70px] rounded-[20px]" src={selectedVault.thumbnail_image} />
                            <div className="flex flex-col">
                                <p className="uppercase text-sm font-semibold">{selectedVault.model_type}</p>
                                <p className="">{selectedVault.name}</p>
                            </div>
                        </div>
                        <div onClick={() => handleVaultSelect(null)} >
                            <CircleX />
                        </div>
                    </div>
                )}
            </div>
            <div className="flex w-full gap-14 ">
                <div className="flex md:flex-row flex-col gap-12 w-full items-center">
                    <div className="relative w-1/2">
                        <p>Description</p>
                        <textarea
                            placeholder="Enter image prompt here"
                            className="bg-gray-900 text-white rounded-md text-sm-sans font-light border-none h-[173px] mt-5 p-5 w-full resize-none overflow-y-auto focus:ring-0 outline-none focus:shadow-none"
                            value={form.watch("prompt")}
                            onChange={(e) => { setValue("prompt", e.target.value) }}
                        />
                        {isLoading ? (
                            <Button
                                className="flex w-[96px] gap-1 border-gray-600 h-[35px] p-2 hover:bg-black hover:text-white fill-white absolute bottom-5 right-5 z-10"
                                type="button"
                                variant="outline"
                                disabled
                            >
                                <img className="h-4" src={loading_light} alt="Loading..." />
                            </Button>
                        ) : (
                            <Button
                                onClick={enhanceTextPrompt}
                                type="button"
                                variant="outline"
                                className="flex gap-1 w-[96px] h-[35px] border-gray-600 p-2 hover:bg-black hover:text-white fill-white absolute bottom-5 right-5 z-10"
                            >
                                <>
                                    <Sparkles />
                                    Rewrite
                                </>
                            </Button>
                        )}
                    </div>
                    <div className="flex flex-col gap-8 w-1/2 pt-5">
                        <Button variant="primary" className="min-w-[341px] min-h-[65px] text-base font-semibold hover:bg-white border-none">Generate image</Button>
                        <Button variant="outline" type="button" className="min-w-[341px] min-h-[65px] text-base font-semibold border-gray-700" onClick={() => setAspectRatioModalOpen(true)}>Aspect ratio</Button>
                    </div>
                </div>
            </div>



        </div>
    )

}

export default ImageDetails;

