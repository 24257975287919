import React, { useCallback, useState, useEffect } from "react";
import Panel from "./panel";
import { Button } from "../../../../common/components/ui/button";
import { useGenerativeAPI } from "../../../../api/generative_ai_api";
import VaultDropdown from "../vault_dropdown";
import { GenerativeModel, VersionHistory } from "../../models/image_generator";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../common/components/ui/popover";
import { InfoIcon, Sparkles } from "../../../../common/icons/icons";
import loading_light from "../../../../common/components/ui/loading_light.gif";

const EditDirectlyPanel = ({
    modelList,
    cursorSize,
    setCursorSize,
    resetDraw,
    undoLastDraw,
    generateInpaint,
    mask,
    expanded,
    handleToggleExpand,
    currentVersionHistory,
    showInpaint,
    closePanel,
    helperText }: {
        modelList: GenerativeModel[],
        cursorSize: number,
        setCursorSize: (size: number) => void,
        resetDraw: () => void,
        undoLastDraw: () => void,
        generateInpaint: (prompt: string, modelWeights: { id: string; weight: number; }[], mask: string | null) => void,
        showInpaint: (mask: any) => void,
        mask: any,
        currentVersionHistory: VersionHistory | null,
        closePanel?: () => void,
        expanded: boolean | undefined,
        handleToggleExpand: () => void,
        helperText?: string
    }) => {
    const [selectedModel, setSelectedModel] = useState<GenerativeModel | null>(null);
    const [inpaintPrompt, setInpaintPrompt] = useState<string>("");
    const [isPromptLoading, setIsPromptLoading] = useState(false);
    // const [ selectedInpaintFile, setSelectedInpaintFile ] = useState<File | null>(null);

    const { enhancePrompt } = useGenerativeAPI();

    const handleSelectModel = (model: any) => {
        setSelectedModel(model);
        const regex = /#\w+/g;
        setInpaintPrompt((prevPrompt) => {
            const promptWithoutOldTag = prevPrompt.replace(regex, "").trim();

            const newGenerativeTag = `#${model.generative_tag}`;
            const updatedPrompt = `${newGenerativeTag} ${promptWithoutOldTag}`.trim();

            return updatedPrompt;
        });
    }

    const handleBrushSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCursorSize(Number(e.target.value));
    }

    useEffect(() => {
        const tagMatch = inpaintPrompt.match(/#(\w+)/);
        if (!tagMatch) {
            setSelectedModel(null);
        }
        if (tagMatch) {
            const generativeTag = tagMatch[1];
            const matchingModel = modelList.find(
                model => model.generative_tag === generativeTag
            );

            if (matchingModel) {
                setSelectedModel(matchingModel);
            }
        }
    }, [inpaintPrompt, modelList]);

    useEffect(() => {
        resetDraw();
    }, [currentVersionHistory]);

    const triggerGenerateInpaint = useCallback(() => {
        const modelWeights = [];
        if (selectedModel?.entity_type === "LICENSABLE_PROPERTY") {
            modelWeights.push({ id: selectedModel.id, weight: 0.8 });
        }
        if (selectedModel?.entity_type === "USER_PRODUCT") {
            modelWeights.push({ id: selectedModel.id, weight: (modelWeights.length === 0 ? 0.8 : 0.2) });
        }
        generateInpaint(
            inpaintPrompt,
            modelWeights,
            mask,
            // selectedInpaintFile?.id || null
        )
        resetDraw();
        setInpaintPrompt("");
    }, [selectedModel, inpaintPrompt, mask]);

    const enhanceTextPrompt = async () => {
        setIsPromptLoading(true);
        const response = await enhancePrompt(inpaintPrompt, selectedModel ? [selectedModel.id] : []);
        if (response && response.enhanced) {
            setInpaintPrompt(response.enhanced);
        }
        setIsPromptLoading(false);
    }

    return (
        <Panel toggleExpanded={handleToggleExpand} helperText={helperText} isExpanded={expanded} title="Edit directly" closePanel={closePanel}>
            <div className="space-y-6">
                <div>
                    <p>Brush size</p>
                    <input className="accent-brand-yellow bg-gray-100 focus:ring-0 w-full focus:outline-none border-none" type="range"
                        value={cursorSize}
                        min="10"
                        max="100"
                        onChange={handleBrushSizeChange} />
                    <div className="text-sm-sans flex justify-between">
                        <p>0</p>
                        <p>100</p>
                    </div>
                </div>
                <div>
                    <p className="">Vault</p>
                    <VaultDropdown modelData={modelList} selectedModelId={selectedModel?.id} onSelect={handleSelectModel} />
                </div>
                <div className="flex flex-col gap-3">
                    <div className="flex gap-2">
                        <p className="">Smart Touch-Up description</p>
                        <Popover>
                            <PopoverTrigger>
                                <InfoIcon />
                            </PopoverTrigger>
                            <PopoverContent className="text-base bg-brand-yellow border-none">
                                Any editing you do to this image will only change the inpainted area
                            </PopoverContent>
                        </Popover>
                    </div>
                    {/* TODO seperate text area to own component */}
                    <div className="relative">
                        <textarea
                            placeholder="Enter image prompt here"
                            className="bg-gray-700 rounded-lg p-4 text-sm-sans text-white border-none outline-none h-[145px] w-full resize-none"
                            value={inpaintPrompt} onChange={(e) => setInpaintPrompt(e.target.value)}
                        />
                        {isPromptLoading ? (
                            // TODO fix bg-black
                            <Button
                                className="flex w-[96px] bg-black gap-1 border-gray-600 h-[35px] p-2 hover:bg-black hover:text-white fill-white absolute bottom-5 right-5 z-10"
                                type="button"
                                variant="outline"
                            >
                                <img className="h-4" src={loading_light} alt="Loading..." />
                            </Button>
                        ) : (
                            <Button
                                onClick={enhanceTextPrompt}
                                type="button"
                                variant="outline"
                                className="flex gap-1 bg-black w-[96px] h-[35px] border-gray-600 p-2 hover:bg-black hover:text-white fill-white absolute bottom-5 right-5 z-10"
                            >
                                <>
                                    <Sparkles />
                                    Rewrite
                                </>
                            </Button>
                        )}
                    </div>
                </div>

                <Button onClick={triggerGenerateInpaint} className="w-full border-none" variant="primary">Generate inpainting</Button>
                <div className="flex flex-row gap-2">
                    <Button className="rounded-[10px]" onClick={resetDraw}>Reset inpainting</Button>
                    <Button className="rounded-[10px]" onClick={undoLastDraw} >Undo</Button>
                </div>

                {/* <Button onClick={() => showInpaint(mask)}>Show inpaint</Button> */}
            </div>
        </Panel>
    )
}

export default EditDirectlyPanel;